.changePasswordContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formText {
    color: gray;
    margin-bottom: 25px !important;
}

.centerFormGroup {
    display: flex;
    justify-content: center;
    flex-direction: column;
}