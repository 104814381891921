.minWidth {
    min-width: 350px;
}

.navbar {
    padding-top: 0em !important;
    padding-left: 0em !important;
    padding-bottom: 0em !important;
}

.navbar-brand {
    padding-top: 0em !important;
    padding-left: 0em !important;
    padding-bottom: 0em !important;
}

.nav-cog:hover {
    border-bottom: 3px solid white;
}

.dropdown-toggle:hover {
    color: white;
}

.dropdown-toggle {
    color: white;
}

.nav-link.active {
    border-bottom: 3px solid white !important;
}

.logo {
    width: 60%;
    height: 80%;
}

.activeColor.active {
    background-color: #009DDF !important;
    color: white !important;
}

.activeColor:hover {
    background-color: #009DDF !important;
    color: white !important;
}

.logoContainer {    
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50px;
    width: 90px;
}

.iconSize {
    font-size: 40;
}