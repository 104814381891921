/* make the customizations */
$theme-colors: (
        "primary": #00358A,
        "secondary": #009DDF
);

$spacer: 1rem !default;
$spacers: (
        6: ($spacer * 5),
        7: ($spacer * 10)
);

/* import bootstrap to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap";