.button-bar {
    display: flex;
    margin-top: 2.5em;
    align-items: center;
    margin-bottom: 1.0em;
    justify-content: center;
}

.button-logbook {
    color: black !important;
    max-width: 135px !important;
    min-width: 135px !important;
    border-radius: 12px !important;
}

.button-logbook:hover {
    color: white !important;
}

.button-logbook.active {
    color: white !important;
}