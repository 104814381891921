.customRow {
    display: flex;
    margin-top: 2.5em;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.0em;
}

.clickable-text {
    color: #009DDF; 
}

.clickable-text:hover {
    cursor: pointer;
    text-decoration: underline;
}