.logbook-status {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    object-position: center;
}

.logbook-connector {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    & > img {
        height: 100px;
    }
}

.logbook-info {
    margin-top: 2.5em;
    padding-bottom: 95px;
}

.logbook-title {
    font-size: 14px;
}