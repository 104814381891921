.main-sidebar {
    right: 0;
    z-index: 5;
    height: 100%;
    display: flex;
    position: fixed;
    max-width: 35vw;
    flex-direction: row;
    transition: right 0.3s ease-in-out;
}

@media screen and (max-width: 1700px) {
    .main-sidebar {
        max-width: 40vw;
    }
}

@media screen and (max-width: 1200px) {
    .main-sidebar {
        max-width: 50vw;
    }
}

.main-sidebar-filter-active {
    right: 0;
    z-index: 5;
    height: 100%;
    display: flex;
    position: fixed;
    max-width: 30vw;
    padding-top: 120px;
    flex-direction: row;
    transition: right 0.3s ease-in-out;
}

@media screen and (max-width: 1700px) {
    .main-sidebar-filter-active {
        max-width: 40vw;
    }
}

@media screen and (max-width: 1200px) {
    .main-sidebar-filter-active {
        max-width: 50vw;
    }
}

.sidebar {
    z-index: 1;    
    display: flex;
    position: relative;
    background: white;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    > .inner-padding {
        padding: 2rem;
        overflow: scroll;
    }

    .underline {
        width: 100%;
        margin-left: 0;
        border-top: thin solid darkgray;
    }

    .header {
        font-size: 21px;
        font-weight: 700;
        padding: 0 0 10% 0;
        text-align: center;
    }
}

::-webkit-scrollbar {
    width: 0
}