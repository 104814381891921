.divider {
    width: 100%;
    margin-top: 2.5em;
    margin-bottom: 1.0em;
    border-top: thin solid darkgray;
}

.margin {
    margin-left: 50px; 
    margin-right: 50px; 
}

.backButton {
    display: flex;
    margin-top: 0.75em;
    position: absolute;
    margin-left: -5.0em;
    color: black !important;
}

.customRow {
    flex: 1;
    display: flex;
    flex-direction: row;
}