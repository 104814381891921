.header {
    font-weight: 700;
}

.date {
    font-weight: 400;
    font-size: 14px;
    color: gray;
}

.message {
    margin-top: 5px;
}

.warning-item {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}