.export-button {
    min-width: 80% !important;
    max-height: 45px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;

    & > img {
        height: 30px;
        margin-left: 5px;
    }
}