.button-export {
    max-width: 305px !important;
    min-width: 305px !important;
    font-weight: 700 !important;
    max-height: 50px !important;
    border-radius: 16px !important;

    & > img {
        height: 30px;
        margin-left: 5px;
    }
}

.button-text {
    display: flex;
    align-items: center;
    justify-content: center;
}