.containerBackground {
    z-index: 2;
    position: fixed;
    min-height: 120px;
    background-color: #009DDF;
}

.marginTop {
    margin-top: 15px;
}

.ellipsis {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.responsiveText{
    vertical-align: top;
    font-size: calc(7px + 0.4vw);
}

.customWidth {
    min-width: 78px;
    max-width: 100px;
}