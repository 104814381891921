.loginContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 250px;
    height: 250px;
}

.subtitle {
    color: #009DDF;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 25px !important;
    margin-bottom: 50px !important
}

.formText {
    margin-bottom: 10px !important;
}

.minWidth {
    min-width: 350px;
}

body {
    height: 100vh;
}