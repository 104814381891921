.defaultAlert {
    top:0 ;
    left:0;
    right:0;
    bottom:0;
    width:400px;
    height:175px;
    margin:0!important;
    margin:auto!important;
    overflow-y:auto!important;
    position:absolute !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}