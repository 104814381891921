.cluster-sidebar {
    flex: 3;
    display: flex;
    overflow-y: auto;
    min-width: 220px;
    height: auto + 50px;
    background: #f5f5f5;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    > .container {
        cursor: pointer;
        padding-top: 10px;
        padding-right: 24px;
        padding-bottom: 10px;
        transition: all 200ms;
        border-bottom: thin solid darkgray;
    
        &:hover, &.container__active {
            color: white;
            background-color: #009ddf;
            transition: background-color 200ms linear;

            .title {
                color: white;
            }

            .location {
                color: white;
            }

            .warning {
                color: white;
                background-color: #009ddf;
                transition: background-color 200ms linear;
            }
        }

        .warning {
            height: 50%;
            display: grid;
            gap: 0px 0px;
            padding-top: 5px;
            overflow: hidden;
            align-items: center;
            transition: all 200ms;
            justify-content: center;
            background-color: #f5f5f5;
            grid-template-columns: 30px 150px ;
            
            .warning-image {
                height: 30px;
                padding-right: 5px;
            }
    
            .text {
                width: 77%;
                font-size: 10px;
                white-space: initial;
                display: inline-block;
            }
        }
    }

    .title {
        color: black;
        font-size: 18px;
        font-weight: 700;
        line-height: 80%;
    }

    .location {
        padding-top: 2px;
        font-size: 14px;
        color: gray
    }
}