
.main-warning {
    z-index: 1;
    right: 3%;
    bottom: 8%;
    width: 18%;
    height: 60%;
    position: absolute;
    border-radius: 25px;
    background: whitesmoke;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    > .inner-padding {
        height: 75%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.warning {
    height: 95%;
    display: flex;
    background:white;
    overflow-y: scroll;
    flex-direction: column;
}

.export-warning {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.underline {
    width: 95%;
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: thin solid darkgray;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}