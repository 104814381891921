.minimize-button {
    top: -25px;
    right: -25px;
    display: flex;
    min-width: 50px;
    min-height: 50px;
    border-width: 4px;
    border-radius: 50%;
    position: absolute;
    background: white;
    align-items: center;
    justify-content: center;
    border-color: #009DDF;
}

.minus {
    max-width: 30px;
}