.status-info {
    display: grid;
    grid-gap: 10px;
    padding-top: 15px;
    align-items: center;
    padding-bottom: 15px;
    grid-template-columns: auto auto;

    > div {
        text-align: center;
    }
}

.battery-info, .load-factor-info {
    display: grid;
    gap: 0px 10px;
    font-size: 20px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-template-columns: auto 70px;

    & > img {
        height: 50px;
    }
}

.sub-text {
    color: gray;
    font-size: 12px;
    line-height: 80%;
    margin-top: 5px;
}