.load-info > * {
    display: block;
    margin: 15px 0;
}

.row-load {
    display: grid;
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    grid-template-columns: 50px 12vw;

    .load-image {
        width: 100%;
        max-width: 35px;
    }
}

.load-text {
    width: 80%;
    line-height: 135%;
    display: inline-block;

    .title {
        font-size: 17px;
        white-space: initial;
        display: inline-block;
    }
    
}