
.warnings {
    font-size: 16px;
    border: 0 !important;
    color: white !important;
}

.left-part {
    background-color: white;
    padding-top: 4px;
    padding-bottom: 9px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 18px;
}

.toggle-button {
    padding: 0px !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-right: 10px !important;
    background-color: #00358A !important;
    border: thin solid white !important;
}

.toggle-button-text {
    display: flex;
    justify-content: center;
}

.toggle-button-active {
    background-color: #009DDF !important;
    color: white !important;
    padding: 0px !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-right: 10px !important;
    border: thin solid white !important;
}

.toggle-button:hover {
    background-color: #009DDF !important;
    color: white !important;
}

img {
    height: 35px;
    padding: 4px 4px 4px 4px;
}