.padding {
    padding-top: 5px;
    padding-left: 15px;
}

.paddingTop {
    padding-top: 5px;
}

.textColorGrey {
    color: #666666
}