.row-warning {
    display: grid;
    gap: 0px 10px;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
    grid-template-columns: 50px 200px ;

    .warning-image {
        height: 45px;
        padding-right: 10px;
    }
}

.row-no-warning {

    display: flex;
    flex-direction: column;
    justify-content: center;

    .no-warning-image {
        height: 50px !important;
    }
}

.warning-text {
    line-height: 115%;
    display: inline-block;
    
    .title {
        font-size: 18px;
        font-weight: 500;
    }

    .sub-text {
        color: gray;
        font-size: 12px;
        line-height: 80%;
    }
}